import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'My Projects',
    paragraph: 'These are the projects I have worked on before or have completed! Some of these are public or private. If you are wanting any type of work done contact me!'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

          <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <Image
                      src={require('./../../assets/images/products/software-icon.png')}
                      alt="Features tile icon 06"
                      width={81}
                      height={81} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Home Detection
                    </h4>
                  <p className="m-0 text-sm">
                    Scrape your local network for devices and get notified when they connect or disconnect. 
                    </p>
                </div>
              </div>
            </div>

          <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <Image
                      src={require('./../../assets/images/products/folder-icon.png')}
                      alt="Features tile icon 06"
                      width={81}
                      height={81} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    FileOrg
                    </h4>
                  <p className="m-0 text-sm">
                  A application that moves your files into specific folder based on its file extension for better organization.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <Image
                      src={require('./../../assets/images/products/faction-icon.png')}
                      alt="Atlas Factions"
                      width={100}
                      height={100} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Atlas Factions
                    </h4>
                  <p className="m-0 text-sm">
                    A full faction fork revamp with unique features, advanced menu and popular server features!
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <Image
                      src={require('./../../assets/images/products/scane-icon.png')}
                      alt="Atlas Harvesterhoes"
                      width={175}
                      height={175} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Atlas Harvester Hoes
                    </h4>
                  <p className="m-0 text-sm">
                    This allows players to have a way to grind for money! Comes with multiple upgradeds, boosters and multipliers they have to unlock!
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <Image
                      src={require('./../../assets/images/products/sand-icon.png')}
                      alt="Atlas Sandbot"
                      width={81}
                      height={81} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Atlas Sandbot
                    </h4>
                  <p className="m-0 text-sm">
                  A sandbot that increases the speed and efficiency of cannoning! It generates sand in a quick manner for user's sand compactors on their cannon.                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <Image
                      src={require('./../../assets/images/products/grass-icon.png')}
                      alt="Atlas Printer"
                      width={81}
                      height={81} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Atlas Printer
                    </h4>
                  <p className="m-0 text-sm">
                  This allows players to print with no limit and with extended block placement radius; While also charging them per block placement.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <Image
                      src={require('./../../assets/images/products/helmet-icon.png')}
                      alt="Atlas Tracker"
                      width={87}
                      height={87} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Atlas Tracker
                    </h4>
                  <p className="m-0 text-sm">
                    This allows players to view relations with colored armor on other players with visual effects!
                    </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;